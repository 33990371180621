import React, { useState } from 'react';
import './section-title.scss';
function SectionTitle(props) {
  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active)
  }

  return (
    <h2 className='section-title'>{props.title_1}<br />{props.title_2}</h2>);
  }
export default SectionTitle;