import './camping_classes.scss';
import './../../style/style.scss';
import icon_bag from '../../images/icon-bag.png';
import icon_hiking from '../../images/icon-hiking.png';
import icon_montain from '../../images/icon-montain-s.png';

import React, { useState } from 'react';
import Box_class from './box-class';
import Btn  from '../btn/btn';

function Camping_classes(props) {
  return (
    <section id="section-campaign">
      <h2>HIKING AND<br />CAMPING CLASSES</h2>
      <div className='box-wrapper'>
        <Box_class image={icon_bag} h="activities" text="Lorem ipsum dolor sit amet consectetur adipiscing, elit molestie posuere" url="https://google.com" />
        <Box_class image={icon_hiking} h="HIKING" text="Lorem ipsum dolor sit amet consectetur adipiscing, elit molestie posuere" url="https://google.com" />
        <Box_class image={icon_montain} h="MOUNTAINS" text="Lorem ipsum dolor sit amet consectetur adipiscing, elit molestie posuere" url="https://google.com" />
      </div>
    </section>
);
}

export default Camping_classes;
