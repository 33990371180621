import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './components/header/header';
import Mainv from './components/top/mainv';
import stylecss from './style/style.scss';
import topcss from './style/top.scss';
import reportWebVitals from './reportWebVitals';
import Schedule from './components/top/schedule';
import Banner_national from './components/top/banner_national';
import Book_climbing from './components/top/book-climbing';
import Autumn from './components/top/autumn';
import Banner_hiking from './components/top/banner-hiking';
import Camping_classes from './components/top/camping_classes';
import Ice_climbing from './components/top/ice_climbing';
import Banner_green from './components/top/banner-green';
import Footer from './components/footer/footer';
import Portfolio from './components/top/portfolio';
const header = ReactDOM.createRoot(document.getElementById('header'));
const mainv = ReactDOM.createRoot(document.getElementById('mainv'));
const schedule = ReactDOM.createRoot(document.getElementById('schedule'));
const banner_national = ReactDOM.createRoot(document.getElementById('banner-national'));
const book_climbing = ReactDOM.createRoot(document.getElementById('book-climbing'));
const visit_autumn = ReactDOM.createRoot(document.getElementById('visit-autumn'));
const banner_hiking = ReactDOM.createRoot(document.getElementById('banner-hiking'));
const camping_classes = ReactDOM.createRoot(document.getElementById('camping_classes'));
const ice_climbing = ReactDOM.createRoot(document.getElementById('ice-climbing'));
const banner_green= ReactDOM.createRoot(document.getElementById('banner-green'));
const footer = ReactDOM.createRoot(document.getElementById('footer'));
const portfolio = ReactDOM.createRoot(document.getElementById('portfolio'));
header.render(
  <React.StrictMode>
    <Header />
  </React.StrictMode>
);

mainv.render(
  <React.StrictMode>
    <Mainv />
  </React.StrictMode>
);

schedule.render(
  <React.StrictMode>
    <Schedule />
  </React.StrictMode>
);

banner_national.render(
  <React.StrictMode>
    <Banner_national />
  </React.StrictMode>
);

book_climbing.render(
  <React.StrictMode>
    <Book_climbing />
  </React.StrictMode>
);

visit_autumn.render(
  <React.StrictMode>
    <Autumn />
  </React.StrictMode>
);

banner_hiking.render(
  <React.StrictMode>
    <Banner_hiking />
  </React.StrictMode>
);

camping_classes.render(
  <React.StrictMode>
    <Ice_climbing />
  </React.StrictMode>
);

camping_classes.render(
  <React.StrictMode>
    <Camping_classes />
  </React.StrictMode>
);

banner_green.render(
  <React.StrictMode>
    <Banner_green />
  </React.StrictMode>
);

footer.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>
);

portfolio.render(
  <React.StrictMode>
    <Portfolio />
  </React.StrictMode>
);
reportWebVitals();
