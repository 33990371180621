import './ice_climbing.scss';
import './../../style/style.scss';

import React, { useState } from 'react';
import Box_class from './box-class';
import Btn  from '../btn/btn';

function Ice_climbing(props) {
  return (
    <section id="section-ice">
      <h2>mountaineering <br />ice Climbing</h2>
      <h3>2. activities</h3>
      <p className='lead'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
      <p className='bottom'>10 modules <br />divided into 7 weekends</p>
      
      <div className='box-price'>
        <p className='date'>23.06.20<br /><span className='hs'>20.00 HS</span></p>
        <p className='price'>Price<br />$900</p>
      </div>
    </section>
);
}

export default Ice_climbing;
