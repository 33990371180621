import React, { useState } from 'react';
import './btn.scss';
function Btn(props) {
  const [active, setActive] = useState(false);
  return (
    <p className='btn'>
      <a className={props.class} href={props.url}>{props.text}</a>  
    </p>
  )
  }
export default Btn;