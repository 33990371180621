import './schedule-box.scss';
import './../../style/style.scss';
import React, { useState } from 'react';

export interface schedule_box_props {
  id: number;
  day: string;
  list_1:string;
  list_2:string;
  list_3:string;
  icon:Element;
  description:string;
  hour:string;
  circle:string;
  number:string;
}
function Schedule_box(props) {
  return (
    <section className="schedule_box">
      <h3>{props.day}</h3>
      <div className="wrap">
        <ul>
          <li>{props.list_1}</li>
          <li>{props.list_2}</li>
          <li>{props.list_3}</li>
        </ul>
        <div className="image">
          <img src={props.icon} alt={props.day} />
        </div>
      </div>
      <div className="box-bottom">
        <p className="description">{props.description}</p>
        <p className="hour">{props.hour}</p>
        <p className="circle">{props.circle}</p>
        <p className="number">{props.number}</p>
      </div>
    </section>
  );
}
export default Schedule_box;
