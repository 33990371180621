import React, { useState } from "react";
import logo from "./../../images/logo.png";
import "./footer.scss";
import "./../../style/style.scss";
function Footer() {
  const [val, setVal] = useState(" ");
  const onInputValueChanged = (e) => setVal(e.target.value);
  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active);
  };

  return (
    <div>
      <footer className="global-footer">
        <img src={logo} className="logo" alt="hiking Life" />
        <nav>
          <ul>
            <li>
              <a href="#bome" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="#Class">Class</a>
            </li>
            <li>
              <a href="#Tours">Tours</a>
            </li>
            <li>
              <a href="#Maps">Maps</a>
            </li>
            <li>
              <a href="#Contact">Contact</a>
            </li>
          </ul>
        </nav>
        <p className="ds">
          <a href="https://direction-studio.jp" target="_blank">
            Direction Studio
          </a>
          のReact制作のサンプルサイトです。
        </p>
        <p className="copy">
          Design著作者：
          <a href="https://jp.freepik.com/free-psd/hiking-landing-page-template_7442776.htm#query=web%20templates&position=26&from_view=keyword&track=ais&uuid=7e30873c-15c4-48a5-8bb0-d1c6b3e3febf">
            Freepik
          </a>
        </p>
      </footer>
    </div>
  );
}

export default Footer;
