import './schedule.scss';
import SectionTitle  from '../section-title/section-title';
import Schedule_box  from './schedule-box';
import icon_m from '../../images/icon-montain-s.png';
import icon_b from '../../images/icon-bag.png';
import './../../style/style.scss';
function schedule() {
  return (
    <section id="section-schedule">
      <SectionTitle title_1="HOW TO RECOVER FROM" title_2="A HIKE" />
      <div className="box-schedule clearfix">
        <div className="box">
          <Schedule_box 
            id="1" 
            day="Day1" 
            list_1="- Dolor sit amet consectetur " 
            list_2="- Adipiscing elit aptent " 
            list_3="- Torquent, interdum " 
            icon={icon_m} 
            description="Lorem Ipsum Consectetur 21.09.20" 
            hour="15.30 hs"
            circle="● ● ● ● ●"
            number="5/5" />
        </div>
        {/* box */}
        <div className="box">
          <Schedule_box 
            id="2" 
            day="Day2" 
            list_1="- Dolor sit amet consectetur " 
            list_2="- Adipiscing elit aptent " 
            list_3="- Torquent, interdum " 
            icon={icon_b} 
            description="Lorem Ipsum Consectetur 21.09.20" 
            hour="28.09.20"
            circle="● ● ● ● ●"
            number="3/5" />
        </div>
        {/* box */}
      </div>
        {/* wrap */}
    </section>
  );
}
export default schedule;
