import './book-climbing.scss';
import './../../style/style.scss';
function Book_climbing() {
  return (
    <section className="section-book-climbing">
      <h2>ROCK<br />CLIMBING</h2>
      <h3>1. CLASSES & EVENTS</h3>
      <p className='lead'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
      <p className='bottom'>10 modules <br />divided into 7 weekends</p>
      <div className='box-price'>
        <p className='date'>23.06.20<br /><span className='hs'>20.00 HS</span></p>
        <p className='price'>Price<br />$900</p>
      </div>
    </section>
  );
}

export default Book_climbing;
