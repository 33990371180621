import './banner_national.scss';
import './../../style/style.scss';
import Btn  from '../btn/btn';

function banner_national() {
  return (
    <section className="section-banner_national">
      <Btn url="https://google.con" text="See More" class="white" />
    </section>
  );
}

export default banner_national;
