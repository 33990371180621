import React, { useState } from "react";
import "./hamburger_menu.scss";
import "./../../style/style.scss";
function Menu() {
  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active);
  };

  return (
    <div>
      <div
        className={active ? "openbtn active" : "openbtn"}
        onClick={classToggle}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={active ? "box-menu active" : "box-menu"}>
        <nav>
          <ul>
            <li>
              <a href="#bome" className="active">
                Home
              </a>
            </li>
            <li>
              <a href="#Class">Class</a>
            </li>
            <li>
              <a href="#Tours">Tours</a>
            </li>
            <li>
              <a href="#Maps">Maps</a>
            </li>
            <li>
              <a href="#Contact"></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Menu;
