import './box-photo.scss';
import './../../style/style.scss';
import React, { useState } from 'react';

import Btn  from '../btn/btn';

function Box_photo(props) {
  return (
    <div className='box-photo'>
      <div className="image">
        <img src={props.image} alt="" />
        <h3>{props.h}</h3>
        <p className='text'>{props.text}</p>
        <p className='location'>{props.location}</p>
      </div>
    </div>
);
}

export default Box_photo;
