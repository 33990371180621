import React, { useState } from 'react';
import Menu  from '../hamburger_menu/hamburger_menu';
import logo from './../../images/logo.png';
import './header.scss';
import './../../style/style.scss';
function Header() {
  const [val, setVal] = useState(" ");
  const onInputValueChanged = (e) => setVal(e.target.value);
  const [active, setActive] = useState(false);
  const classToggle = () => {
    setActive(!active)
  }

  return (
      <div>
        <header className="global-header">
          <img src={logo} className="logo" alt="hiking Life" />
          <nav>
            <ul>
              <li><a href="#bome" className="active">Home</a></li>
              <li><a href="#Class">Class</a></li>
              <li><a href="#Tours">Tours</a></li>
              <li><a href="#Maps">Maps</a></li>
              <li><a href="#Contact"></a></li>
            </ul>
            <form action="">
              <input type="text" name="search" className="search" value={val} onChange={onInputValueChanged} />
              <input type="submit" value="submit" className="submit"/>            
            </form>
          </nav>
          <div className={active ? "openbtn active" : "openbtn"} onClick={classToggle}><span></span><span></span><span></span></div>
        </header>
        <Menu />
      </div>
  );
}



export default Header;
