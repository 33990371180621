import './mainv.scss';
import './../../style/style.scss';
function mainv() {
  return (
    <section className="section-mainv">
      <h1>REACT CODING<br />SAMPLE PAGE</h1>
      <p className="lead">Discover your next < br />hike</p>
    </section>
  );
}

export default mainv;
