import './autumn.scss';
import './../../style/style.scss';
import Btn  from '../btn/btn';
import Box_photo from './box-photo';

import photo_1 from '../../images/photo-1.png';
import photo_2 from '../../images/photo-2.png';
import photo_3 from '../../images/photo-3.png';

function Autumn() {
  return (
    <section className="section-autumn">
      <h2>PLACES TO VISIT<br />IN AUTUMN</h2>
      <div className='wrap-photo'>
        <Box_photo image={photo_1} h="MOUNTAIN LOOP" text="Lorem ipsum sit amet, consectetur  adipisicing..." location="Location 0.3 miles." />
        <Box_photo image={photo_2} h="National Park" text="Lorem ipsum sit amet, consectetur  adipisicing..." location="Location 0.2 miles." />
        <Box_photo image={photo_3} h="Canyon Trail" text="Lorem ipsum sit amet, consectetur  adipisicing..." location="Location 0.6 miles." />
      </div>
      <div className='box-btn'>
        <Btn url="https://google.con" text="See More" class="purple" />
      </div>
    </section>
  );
}

export default Autumn;
