import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import './portfolio.scss';
import './../../style/style.scss';
import p_1 from '../../images/p-1.png';
import p_2 from '../../images/p-2.png';
import p_3 from '../../images/p-3.png';
function Portfolio() {
	const [toggler, setToggler] = useState(false);

	return (
		<>
      <button className="portfolio_btn" onClick={() => setToggler(!toggler)}>
				制作について
			</button>
			 <FsLightbox
				toggler={toggler}
				sources={[
					'/static/media/p-2.01f5f9bbc95fdb56a7ed.png',
					'/static/media/p-1.d798e7e24f02fe4d0fb9.png',
					'/static/media/p-3.de9f744852f845b312fd.png',
				]}
			/>
		 </>
	);
}

export default Portfolio;